.relatedproducts{
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    height: 90vh;
}

.relatedproducts h1{
    color: #171717;
    font-size: 50px;
    font-weight: 600;
}

.relatedproducts hr{
    width: 200px;
    height: 6px;
    border-radius: 10px;
    background: #252525;
}

.relatedproducts-item{
    margin-top: 50px;
    display: flex;
    gap: 30px;
}