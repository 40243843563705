.catalogo-banner{
  display: block;
  margin-bottom: 20px;
  width: 100%;
}

.catalogo-info h2{
  align-content: center;
}

.catalogo-type1{
  margin-top: 25px;
  display: flex;
  gap: 50px;
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
}

.catalogo-type2{
  margin-top: 25px;
  display: flex;
  gap: 50px;
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
}

.catalogo-elements{
  display: block;
  margin-bottom: 2px;
  width: 100%;
  /* border: solid 2px #ccc;*/
  border-radius: 1%;
  box-shadow: 10px 10px 10px gray;
}

.catalogo-elements:hover{
  transform: scale(1.05);
  transition: 0.6s;
}

/* Media queries para diferentes tamaños de pantalla */
@media (min-width: 1024px) {
  .catalogo-type1, .catalogo-type2 {
    grid-template-columns: repeat(2, 1fr); /* Dos columnas en pantallas grandes */
  }
}

@media (max-width: 1024px) {
  .catalogo-banner {
    width: 100%; /* Asegura que el banner ocupe todo el ancho disponible */
  }

  .catalogo-type1, .catalogo-type2 {
    grid-template-columns: 1fr; /* Una columna en pantallas pequeñas */
  }

  .catalogo-elements {
    width: 100%;
  }
}

  