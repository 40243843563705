.productdisplay{
    display: flex;
    margin: 0px 170px;
}

.productdisplay-left{
    display: flex;
    gap: 17px;
}

.productdisplay-img-list{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.productdisplay-img-list img{
    height: 163px;
}

.productdisplay-main-img{
    height: 500px;
    max-width: 700px;
}

.productdisplay-right{
    margin: 0px 70px;
    display: flex;
    flex-direction: column;
}

.productdisplay-right h1{
    color: #3d3d3d;
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 15px;
}

.productdisplay-right p{
    color: #3d3d3d;
    font-weight: 400;
    line-height: 1.5;
}

.productdisplay-right-description{
    margin: 10px 0px 10px 0px;
}

.productdisplay-right button{
    padding: 20px 40px;
    width: 350px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background: #16437E;
    border: none;
    margin-bottom: 10px;
    outline: none;
    display: center;
}

.productdisplay-right button:hover {
    background-color: #0d2a4e;
}

.productdisplay-right-category{
    margin-top: 10px;
}

.productdisplay-right-category span{
    font-weight: 600px;
}

.productdisplay-right-tags{
    margin-top: 10px;
}

@media (max-width: 1280px) {
    .productdisplay {
        flex-direction: column; /* Cambiar a columna en pantallas más pequeñas */
        margin: 0px 60px;
    }

    .productdisplay-left,
    .productdisplay-right {
        max-width: 100%; /* Asegurar que los elementos ocupen todo el ancho */
        margin: 10px 0; /* Añadir margen entre secciones */
    }

    .productdisplay-img-list img {
        height: 128px;
    }

    .productdisplay-main-img {
        width: 100%; /* Usar todo el ancho disponible */
        height: auto; /* Mantener la proporción de la imagen */
    }

    .productdisplay-right h1 {
        font-size: 2rem; /* Ajustar tamaño de fuente */
    }
}

@media (max-width: 800px) {
    .productdisplay {
        margin: 0px 20px;
    }

    .productdisplay-right h1 {
        font-size: 1.5rem; /* Ajustar tamaño de fuente */
    }

    .productdisplay-right p {
        font-size: 1rem; /* Ajustar tamaño de fuente */
    }

    .productdisplay-right button {
        padding: 8px 16px; /* Ajustar padding */
    }
}

@media (max-width: 500px) {
    .productdisplay {
        margin: 0px 10px;
    }

    .productdisplay-right h1 {
        font-size: 1.2rem; /* Ajustar tamaño de fuente */
    }

    .productdisplay-right p {
        font-size: 0.9rem; /* Ajustar tamaño de fuente */
    }

    .productdisplay-right button {
        padding: 6px 12px; /* Ajustar padding */
    }
}
