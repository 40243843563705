.pasta-tacon{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  min-height: 120vh;
  /* margin-left: 50px; */
}

.pasta-tacon h1{
  color: #171717;
  font-size: 50px;
  font-weight: 600;
  margin-left: -50px;
}

.nuevo-products{
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px; 
  height: 10px;
}

.pasta-tacon hr{
  width: 350px;
  height: 6px;
  border-radius: 10px;
  background: #252525;
  margin-left: -50px;
}

  
  /* Media queries para diferentes tamaños de pantalla */
  @media (max-width: 1280px) {
    .pasta-tacon {
      gap: 20px;
    }
  
    .pasta-tacon h1 {
      font-size: 40px;
    }
  
    .pasta-tacon hr {
      width: 160px;
      height: 4px;
    }
  
    .nuevo-products {
      grid-template-columns: repeat(2, 1fr); /* Dos columnas en pantallas pequeñas */
      gap: 20px;
    }
  }
  
  @media (max-width: 1024px) {
    .pasta-tacon {
      gap: 15px;
    }
  
    .pasta-tacon h1 {
      font-size: 30px;
    }
  
    .pasta-tacon hr {
      width: 120px;
      height: 3px;
    }
  
    .nuevo-products {
      grid-template-columns: repeat(2, 1fr); /* Dos columnas en pantallas pequeñas */
      gap: 15px;
    }
  }
  
  @media (max-width: 800px) {
    .pasta-tacon {
      gap: 10px;
    }
  
    .pasta-tacon h1 {
      font-size: 20px;
    }
  
    .pasta-tacon hr {
      width: 100px;
    }
  
    .nuevo-products {
      grid-template-columns: 1fr; /* Una columna en pantallas muy pequeñas */
      gap: 10px;
    }
  }
  


