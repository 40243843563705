.hero {
    margin-bottom: 20px;
}

.hero .slick-slide a {
    width: 100%;
    height: 100%;
}

.hero .slick-slide img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.contenedor-texto {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    background-color: #194197; /* Fondo para resaltar el texto */
    color: white;
    padding: 20px;
}

.contenedor-texto h1 {
    font-size: 3rem; /* Usar rem para tamaño de fuente flexible */
    margin: 0; /* Eliminar márgenes para mejor control */
}

.contacto-btn button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 310px;
    height: 70px;
    border-radius: 75px;
    border: none;
    cursor: pointer;
    margin-top: 30px;
    background-color: #171717;
    color: #ffffff;
    font-size: 22px;
    font-weight: 500;
}

/* Media queries para diferentes tamaños de pantalla */
@media (max-width: 1280px) {
    .contenedor-texto h1 {
        font-size: 2.5rem; /* Ajustar tamaño de fuente */
    }

    .contacto-btn button {
        gap: 10px;
        width: 250px;
        height: 60px;
        margin-top: 10px;
    }
}

@media (max-width: 1024px) {
    .contenedor-texto h1 {
        font-size: 2rem; /* Ajustar tamaño de fuente */
    }

    .contacto-btn button {
        gap: 10px;
        width: 220px;
        height: 50px;
    }
}

@media (max-width: 800px) {
    .contenedor-texto h1 {
        font-size: 1.5rem; /* Ajustar tamaño de fuente */
    }

    .contacto-btn button {
        gap: 10px;
        width: 200px;
        height: 40px;
    }
}

@media (max-width: 500px) {
    .contenedor-texto h1 {
        font-size: 1.2rem; /* Ajustar tamaño de fuente */
    }

    .contacto-btn button {
        font-size: 14px;
        gap: 10px;
        width: 200px;
        height: 40px;
    }
}

