.shopcategory-banner{
    display: block;
    margin-bottom: 20px;
    width: 100%;
}

.shopcategory-indexSort {
    display: flex;
    margin: 0px 170px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Permite que los elementos se ajusten según el ancho disponible */
}

.sort-container {
    display: flex;
    align-items: center;
    gap: 10px; /* Espacio entre el texto y el select */
}

.sort-label {
    margin: 0; /* Elimina márgenes predeterminados */
    padding-right: 5px; /* Pequeño espacio entre el texto y el select */
}

.shopcategory-sort select {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #888;
    cursor: pointer;
}


/* Estilo para la flecha desplegable, usando un pseudo-elemento */
.shopcategory-sort select::-ms-expand {
    display: none; /* Oculta la flecha predeterminada en IE */
}

.shopcategory-sort select:after {
    content: '\25BC'; /* Carácter Unicode para la flecha hacia abajo */
    font-size: 10px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Evita que la flecha interfiera con el clic */
}

/* Estilos para mejorar la apariencia de las opciones cuando se despliega el select */
.shopcategory-sort select option {
    padding: 8px 10px; /* Espaciado interno para hacer las opciones más accesibles */
    background: white; /* Fondo blanco para las opciones */
    color: #333; /* Texto oscuro para las opciones */
}

/* Añadir transiciones suaves al abrir y cerrar el select */
.shopcategory-sort select {
    transition: all 0.3s ease;
}

/* Estilos adicionales para hover y focus para mejorar la accesibilidad */
.shopcategory-sort select:hover,
.shopcategory-sort select:focus {
    border-color: #555; /* Oscurece el borde al pasar el mouse o enfocar */
    box-shadow: 0 2px 8px rgba(0,0,0,0.2); /* Sombra más pronunciada al interactuar */
}


.shopcategory-indexSort p span{
    font-weight: 600;
}

.shopcategory-products{
    padding: 40px 120px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 80px;
    column-gap: 40px;
    align-items: space-between; 
    background-image: url('../../Components/Assets/background.png');
}

.shopcategory-loadmore{
    display: flex;
    color:white;
    justify-content: center;
    align-items: center;
    margin: 100px auto;
    width: 233px;
    height: 69px;
    border-radius: 75px;
    background-color: #0A66C2;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
  }
  
.searchInput {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px 0 0 5px;
    width: 200px;
  }
  
.searchButton {
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 0 5px 5px 0;
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
.searchButton:hover {
    background-color: #e0e0e0;
  }
  
@media(max-width:1280px){
    .shopcategory-banner{
        margin: 20px auto;
        width: 90%;
    }

    .shopcategory-indexSort{
        width: 90%;
        margin: auto;
    }

    .shopcategory-sort{
        padding: 5px 10px;
        font-size: 12px;
    }

    .shopcategory-indexSort p{
        font-size: 12px;
    }

    .shopcategory-products{
        width: 90%;
        margin: 10px auto;
        row-gap: 40px;
    }
    .shopcategory-products .item{
        margin: auto;
    }

    .shopcategory-loadmore{
        margin: 100px auto;
        width: 200px;
        height: 60px;
        font-size: 16px;
    }
}

@media(max-width:800px){
    .shopcategory-banner{
        width: 95%;
    }

    .shopcategory-products{
        width: 95%;
        height: auto;
    }
    .shopcategory-products .item{
        margin: auto;
    }

    .shopcategory-loadmore{
        margin: 60px auto;
        width: 150px;
        height: 40px;
        font-size: 13px;
    }
}

@media(max-width:500px){
    .shopcategory-products{
        grid-template-columns: 1fr 1fr; 
    }
}