.contacto-banner{
    display: block;
    margin-bottom: 20px;
    width: 100%;
}

.contacto p{
    align-content: center;
    text-align: center;
    margin-bottom: 20px;
}

.contacto h1{
    align-content: center;
    text-align: center;
    margin-bottom: 20px;
}

.contacto h2{
    align-content: center;
    text-align: center;
    margin-bottom: 20px;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}

.container-contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 50px;
}

.container-contact h2{
    font-size: 30px;
    text-align: center;
    margin-bottom: 10px;
}

.container-contact form{
    width: 1000px;
    text-align: center;
}

.input-box .input-field {
    width: 100%;
}

.form .input-box{
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.field .itemC{
    width: 100%;
    padding: 18px;
    background: transparent;
    border: 2px solid #171717;
    outline: none;
    border-radius: 6px;
    font-size: 16px;
    margin: 12px 0;
}

form .text-area .field{
    resize: none;
}

.field .error-txt{
    color: red;
    font-size: 14px;
    margin-top: 5px;
    text-align: left;
    display: none;
}

.contacto-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.contacto-btn button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 310px;
    height: 70px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    background-color: #171717;
    color:#ffffff;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
}

.contacto-btn button:hover{
    /* background-color: white;
    border: solid 2px black;
    color: black; */
    border: solid 2px white;
    filter: brightness(90%) invert(1);
}

.contacto-miniBanners{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 50px;
}

.contacto-miniBanners img{
    /* filter: brightness(0) invert(0); */
    padding: 20px;
}

.contacto-miniBanners p{
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    color: white;
    margin-top: 18px;
    margin-right: 10px;
}

.contacto-miniBanners button{
    display: grid;
    grid-template-columns: 1fr 3fr;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 310px;
    height: 70px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    background-color: #171717;
    margin-bottom: 20px;
}



.contacto-miniBanners button:hover{
    border: solid 2px white;
    filter: brightness(90%) invert(1);
}

.alerta {
    text-align: center; /* Centra el texto dentro del contenedor */
    padding: 10px;
    color: white; /* Texto blanco */
    border-radius: 5px;
    width: 100%; /* Ocupa todo el ancho de la pantalla */
    position: fixed; /* Posición fija en la pantalla */
    top: 0; /* En la parte superior de la pantalla */
    left: 0; /* Alineado a la izquierda */
    z-index: 1000; /* Asegura que la alerta se muestre por encima de otros elementos */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Sombra sutil para destacar */
}

.alerta.exito {
    background-color: #4CAF50; /* Verde para éxito */
}

.alerta.error {
    background-color: #f44336; /* Rojo para error */
}

/* .contacto-miniBanners p :hover{
    color: black;
}

.contacto-miniBanners img :hover{
    filter: brightness(100%) invert(1);
} */

/* Media queries para diferentes tamaños de pantalla */
@media (max-width: 1024px) {
    .container-contact h2 {
        font-size: 1.75rem; /* Ajustar tamaño de fuente */
    }

    .form .input-box {
        flex-direction: column; /* Cambiar a columna en pantallas más pequeñas */
    }
}

@media (max-width: 768px) {
    .container-contact {
        padding: 0 20px; /* Reducir padding */
    }

    .container-contact h2 {
        font-size: 1.5rem; /* Ajustar tamaño de fuente */
    }

    .field .itemC {
        padding: 12px; /* Reducir padding para ajustar el tamaño */
    }

    .contacto-btn button, .contacto-miniBanners button {
        width: 100%; /* Asegurar que ocupe todo el ancho disponible */
        max-width: none; /* Eliminar el límite de ancho */
        font-size: 1rem; /* Ajustar tamaño de fuente */
        height: 50px; /* Ajustar altura del botón */
    }

    .contacto-miniBanners {
        flex-direction: column; /* Cambiar a columna en pantallas más pequeñas */
    }

    .contacto-miniBanners p {
        font-size: 1rem; /* Ajustar tamaño de fuente */
    }
}

@media (max-width: 480px) {
    .container-contact {
        padding: 0 10px; /* Reducir padding */
    }

    .container-contact h2 {
        font-size: 1.25rem; /* Ajustar tamaño de fuente */
    }

    .field .itemC {
        padding: 10px; /* Reducir padding para ajustar el tamaño */
    }

    .contacto-btn button, .contacto-miniBanners button {
        width: 100%; /* Asegurar que ocupe todo el ancho disponible */
        max-width: none; /* Eliminar el límite de ancho */
        font-size: 0.875rem; /* Ajustar tamaño de fuente */
        height: 40px; /* Ajustar altura del botón */
    }

    .contacto-miniBanners p {
        font-size: 0.875rem; /* Ajustar tamaño de fuente */
    }
}
