.descriptionbox{
    margin: 120px 170px;
}

.descriptionbox-navigator{
    display:flex;
    width: 100%;
    border: 2px solid #d0d0d0;
    justify-content: center;
}

.descriptionbox-navigator button{
    display: flex;
    background-color: #fbfbfb;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    height: 70px;
    border: 1px solid #d0d0d0;
    color: #555;
    cursor: pointer;
}

.descriptionbox-navigator button.active{
    background: #16437E;
    color: white;
}

.descriptionbox-navigator button:hover{
    background: #0d2a4e;
    color: white;
}

.descriptionbox-nav-box{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    width: 171px;
    height: 70px;
    border: 1px solid #d0d0d0;
}

.descriptionbox-nav-box.fade{
    background: #fbfbfb;
    color: #555;
}

.descriptionbox-description{
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-direction: column;
    gap: 25px;
    border: 1px solid #d0d0d0;
    padding: 60px;
    padding-bottom: 70px;
    background: white;
}

.descriptionbox-description p{
    font-size: 25px;
    font-weight: 400;
    color: #3d3d3d;
    text-align: justify;
}

.descriptionbox-description img{
    height: auto;
    width: 100%;
}

/* Media queries para diferentes tamaños de pantalla */
@media (max-width: 1280px) {
    .descriptionbox {
        margin: 20px;
    }

    .descriptionbox-navigator button {
        font-size: 0.9rem; /* Ajustar tamaño de fuente */
        height: 60px; /* Ajustar altura */
    }

    .descriptionbox-description p {
        font-size: 0.9rem; /* Ajustar tamaño de fuente */
    }
}

@media (max-width: 800px) {
    .descriptionbox {
        margin: 10px;
    }

    .descriptionbox-navigator button {
        font-size: 0.8rem; /* Ajustar tamaño de fuente */
        height: 50px; /* Ajustar altura */
    }

    .descriptionbox-description p {
        font-size: 0.8rem; /* Ajustar tamaño de fuente */
    }
}

@media (max-width: 500px) {
    .descriptionbox {
        margin: 5px;
    }

    .descriptionbox-navigator button {
        font-size: 0.7rem; /* Ajustar tamaño de fuente */
        height: 40px; /* Ajustar altura */
    }

    .descriptionbox-description p {
        font-size: 0.7rem; /* Ajustar tamaño de fuente */
    }
}


.descriptionbox-navigator button.active{
    background: #16437E;
    color: white;
}

.descriptionbox-navigator button:hover{
    background: #0d2a4e;
    color: white;
}

.descriptionbox-nav-box{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    width: 171px;
    height: 70px;
    border: 1px solid #d0d0d0;
}

.descriptionbox-nav-box.fade{
    background: #fbfbfb;
    color: #555;
}

.descriptionbox-description{
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-direction: column;
    gap: 25px;
    border: 1px solid #d0d0d0;
    padding: 60px;
    padding-bottom: 70px;
    background: white;
}

.descriptionbox-description p{
    font-size: 25px;
    font-weight: 400;
    color: #3d3d3d;
    text-align: justify;
}

.descriptionbox-description img{
    height: auto;
    width: 100%;
}