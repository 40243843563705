.item{
    width: 280px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 0px #e0e0e0;
    padding: 10px;
}

.item img{
    width: 260px;
    height: 260px;
}

.model-name{
    color: #374151;
    font-size: 18x;
    font-weight: 600;
    display: flex;
    gap: 20px;
    margin: 6px 0px;
}

.application {
    color: #374151;
    font-size: 18x;
    font-weight: 400;
}

.item:hover{
    transform: scale(1.05);
    transition: 0.6s;
}


@media (max-width: 1280px) {
    .item {
        max-width: 200px;
        font-size: 14px; /* Corrected property from font to font-size */
    }

    .item img {
        max-width: 200px;
    }

    .model-name {
        font-size: 14px;
    }

    .application {
        font-size: 14px;
    }
}

@media (max-width: 1024px) {
    .item {
        max-width: 170px;
        font-size: 13px;
    }

    .item img {
        max-width: 170px;
    }

    .model-name {
        font-size: 13px;
    }

    .application {
        font-size: 13px;
    }
}

@media (max-width: 800px) {
    .item {
        max-width: 120px;
        font-size: 12px;
    }

    .item img {
        max-width: 120px;
    }

    .model-name {
        font-size: 12px;
    }

    .application {
        font-size: 12px;
    }
}

@media (max-width: 500px) {
    .item {
        max-width: 90px;
        font-size: 10px;
    }

    .item img {
        max-width: 90px;
    }

    .model-name {
        font-size: 10px;
    }

    .application {
        font-size: 10px;
    }
}
