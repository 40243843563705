.conocenosBanner{
  display: block;
  margin-bottom: 20px;
  width: 100%;
}

.conocenos{
  display: block;
  margin-bottom: 20px;
  width: 100%;
}

.conocenosGrid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 50px;
  margin-bottom: auto;
}

.conocenosImg{
  max-width: 100%;
  margin: auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.accordion {
  max-width: 100%;
  padding: 20px;
  text-align: left;
  line-height: 1.5;
}

.accordion :hover {
cursor: pointer;
}

article {
border-bottom: 2px solid #9d9d9d;
padding: 1em;
}

article h2 {
font-size: 30px;
color: #404040;
font-weight: 700;
}

article p {
font-size: 20px;
color: #404040;
margin-top: 20px;
}

article ul {
list-style-type: square; /* Choose style that fits your design */
padding-left: 20px; /* Indentation for the list */
margin-top: 20px; /* Spacing above the list */
font-size: 20px; /* Matches the paragraph text size */
color: #404040; /* Matches the paragraph text color */
}

article li {
margin-bottom: 10px; /* Space between list items */
}

input[type="radio"] {
appearance: none;
position: absolute;
opacity: 0; /* use opacity instead of moving off-screen for better accessibility */
}

input[type="radio"] ~ div {
max-height: 0;
overflow: hidden;
transition: max-height 0.5s ease-in-out;
}

input[type="radio"]:checked ~ div {
max-height: 1000px; /* adjust according to content size */
}

/* Media queries para diferentes tamaños de pantalla */
@media (max-width: 1024px) {
  .conocenosGrid {
      grid-template-columns: 1fr; /* Una sola columna en pantallas más pequeñas */
      padding: 20px; /* Reducir padding */
  }

  article h2 {
      font-size: 1.75rem; /* Ajustar tamaño de fuente */
  }

  article p,
  article ul {
      font-size: 1.125rem; /* Ajustar tamaño de fuente */
  }
}

@media (max-width: 768px) {
  .conocenosGrid {
      padding: 10px; /* Reducir padding */
  }

  article h2 {
      font-size: 1.5rem; /* Ajustar tamaño de fuente */
  }

  article p,
  article ul {
      font-size: 1rem; /* Ajustar tamaño de fuente */
  }
}

@media (max-width: 480px) {
  .conocenosGrid {
      padding: 5px; /* Reducir padding */
  }

  article h2 {
      font-size: 1.25rem; /* Ajustar tamaño de fuente */
  }

  article p,
  article ul {
      font-size: 0.875rem; /* Ajustar tamaño de fuente */
  }
}

