.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  box-shadow: 0 1px 3px -2px black;
  position: relative;
}

.nav-logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 50px;
  color: #194197;
  font-size: 20px;
  margin: 0; /* Eliminar margen para alinear correctamente */
  padding: 0; /* Eliminar padding para alinear correctamente */
}

.nav-menu li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  cursor: pointer;
}

.nav-menu hr {
  border: none;
  width: 80%;
  height: 3px;
  border-radius: 10px;
  background: #ff4141;
}

.nav-dropdown {
  display: none;
  cursor: pointer;
}

.nav-dropdown.open {
  transform: rotate(90deg);
}

.buscador-container {
  position: relative;
}

/* Media queries para diferentes tamaños de pantalla */
@media (max-width: 1280px) {
  .navbar {
      padding: 14px 30px;
  }

  .nav-menu {
      gap: 30px;
      font-size: 14px;
  }

  .login-admi {
      margin-top: 20px;
      width: 80px;
      height: 35px;
      font-size: 14px;
  }

  .buscador {
      width: 150px;
      height: 17px;
  }

  .boton-buscar {
      width: 70px;
      font-size: 10px;
      height: 41px;
  }
}

@media (max-width: 1024px) {
  .navbar {
      padding: 12px;
  }

  .nav-menu {
      gap: 30px;
      font-size: 14px;
  }

  .login-admi {
      margin-top: 20px;
      width: 80px;
      height: 35px;
      font-size: 14px;
      margin-left: 10px;
  }
}

@media (max-width: 800px) {
  .navbar {
      padding: 10px 0px;
  }

  .nav-dropdown {
      display: block;
      width: 40px;
      margin-left: auto; /* Mover el dropdown hacia la derecha */
  }

  .nav-menu {
      display: none;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 60px; /* Ajustar la posición para evitar solapamiento */
      left: 0;
      right: 0;
      background-color: white;
      padding: 10px 0;
      box-shadow: 0 1px 3px -2px black;
      z-index: 1000; /* Asegurar que el menú esté encima de otros elementos */
  }

  .nav-menu-visible {
      display: flex;
  }

  .login-admi {
      margin-top: 25px;
      width: 100%;
      height: 35px;
      font-size: 14px;
      justify-content: center;
      align-items: center;
  }
}

@media (max-width: 500px) {
  .navbar {
      padding: 8px 0px;
  }

  .nav-logo {
      transform: scale(0.8);
  }

  .nav-menu {
      top: 45px; /* Ajustar la posición para evitar solapamiento */
      font-size: 14px;
  }

  .login-admi {
      margin-top: 25px;
      width: 100%;
      height: 30px;
      font-size: 14px;
      justify-content: center;
      align-items: center;
  }

  .nav-dropdown {
      display: flex;
      margin-left: auto; /* Asegurar que el dropdown esté a la derecha */
  }

  .buscador {
      font-size: 9px;
      margin-left: 10px;
      width: 100px;
  }
}
